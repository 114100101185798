import React, { useContext, useEffect, useState, useRef } from 'react'
import { BiSolidSend } from 'react-icons/bi'
import { FaFileUpload } from 'react-icons/fa'
import { MdCancel, MdOutlineAddReaction } from 'react-icons/md'
import Picker from '@emoji-mart/react'
import 'react-quill/dist/quill.snow.css'
import './MessageInput.scss'
import { TFunction } from 'i18next'
import { Badge, Divider, Upload } from 'antd'
import { useTranslation } from 'react-i18next'
import { ViewFile } from '../../Viewer/ViewerSlice'
import { setImgToView } from './Previewer/PreviewerSlice'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { AppDispatch, RootState } from '../../../store'
import MessageInputQuill from './MessageInputQuill'
import useEventListener from '../../../utils/hooks/useEventListener'
import { UploadFile } from 'antd/es/upload/interface'
import { FileUploadInProgressInterface } from './FileUploadDialog'
import classNames from 'classnames'
import {
  resetMessageInputDraft,
  setMessageInputDraftEditMsg,
  setMessageInputDraftReplyMsg,
  setMessageInputDraftText,
  setScrolledToBottom,
} from './chatsSlice'
import { useStateWithDebouncedCallback } from '../../../utils/hooks/useStateWithDebouncedCallback'
import useChatDraft from '../../../utils/hooks/useChatDraft'
import { renderIcon } from '../../../utils/Utils'
import Previewer from './Previewer/Previewer'
import { ChatWebSocketConnectionContext } from '../WebsocketConnection/ChatWebSocketConnectionContext'
import useChangeTypingChecker from '../../../utils/hooks/useChangeTypingChecker'
const emptyPattern = /^(\s*<p>\s*(<br>)?\s*<\/p>\s*)*$/

interface CustomToolbarProps {
  text: string
  setText: (text: string) => void
  quillRef: React.RefObject<MessageInputQuill>
}

const CustomToolbar = ({ text, setText, quillRef }: CustomToolbarProps) => {
  const { t } = useTranslation('chat')

  const [isPickerVisible, setPickerVisible] = useState(false)
  const pickerRef = useRef<HTMLDivElement | null>(null)

  /**
   *
   */
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target as Node)) {
        setPickerVisible(false)
      }
    }
    if (isPickerVisible) {
      document.addEventListener('mousedown', handleOutsideClick)
    }
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [isPickerVisible])

  /**
   *
   * @param emoji
   */
  const handleEmojiSelect = (emoji: any) => {
    //prevent line jumps
    const cleanedText = text.replace(/<\/?p>/g, '')

    //Verify that the reference to Quill is valid and calculate where to add the emoji
    if (quillRef?.current) {
      const quillInstance = quillRef.current.quillRef.current?.getEditor()
      const currentSelection = quillInstance?.getSelection()
      const cursorPosition = currentSelection?.index ?? cleanedText.length

      const updatedText =
        cleanedText.slice(0, cursorPosition) +
        emoji.native +
        cleanedText.slice(cursorPosition)

      setText(updatedText)

      //Reposition the cursor after adding the emoji
      setTimeout(() => {
        quillInstance?.setSelection(cursorPosition + emoji.native.length)
      }, 0)
    }

    setPickerVisible(false)
  }

  return (
    <div id="message-input-toolbar">
      <div className="message-input-toolbar-left">
        <button className="ql-bold" title={t('Bold')} />
        <button className="ql-italic" title={t('Italic')} />
        <button className="ql-underline" title={t('Underline')} />
        <button className="ql-strike" title={t('Strike')} />
        <Divider type="vertical" />
        <button className="ql-list" value="ordered" title={t('Ordered list')} />
        <button className="ql-list" value="bullet" title={t('Bullet list')} />
        <Divider type="vertical" />
        <button
          className="ql-emoji"
          title={t('Emojis')}
          onClick={() => setPickerVisible(!isPickerVisible)}
        >
          <MdOutlineAddReaction size="1.2rem" />
        </button>
        {isPickerVisible && (
          <div ref={pickerRef} className="picker-container">
            <Picker onEmojiSelect={handleEmojiSelect} />
          </div>
        )}
        <Divider type="vertical" />
        <button className="ql-upload" title={t('Upload')}>
          <FaFileUpload />
        </button>
      </div>
      <button className="ql-send">
        <BiSolidSend size="1.5rem" />
      </button>
    </div>
  )
}

function getPreparedFiles({
  previousFiles,
  filesInProgress,
  encryptKey,
}: {
  previousFiles?: ViewFile[] | undefined
  filesInProgress?: UploadFile<any>[] | undefined
  encryptKey: string
}) {
  const messageFiles = previousFiles?.map((f) => {
    return {
      response: f,
      uid: f.id,
      name: f.name,
      status: 'done',
      url:
        f.mimeType && f.mimeType.startsWith('image')
          ? `${process.env.REACT_APP_FILE_ENCRYPTOR_V2_BASE_URL}/${f.id}/${encryptKey}`
          : undefined,
      thumbUrl:
        f.mimeType && f.name && !f.mimeType.startsWith('image')
          ? renderIcon(f.mimeType, f.name)
          : undefined,
    } as UploadFile
  })
  const mergedFiles = _.mergeWith(
    _.keyBy(messageFiles, 'uid'),
    _.keyBy(filesInProgress, 'uid'),
    (obj1, obj2) => ({ ...obj1, ...obj2 }),
  )
  const finalMergedArray = _.values(mergedFiles)
  return finalMergedArray
}

interface Props extends FileUploadInProgressInterface {
  onSend: ({
    text,
    files,
    repliedTo,
  }: {
    text: string
    files: string
    repliedTo?: string
  }) => void
  onUpload: () => void
  t: TFunction
  currentChatId: string
  previousFiles?: ViewFile[] | undefined
  encryptKey: string
}

export default function MessageInput({
  onSend,
  onUpload,
  t,
  currentChatId,
  previousFiles,
  encryptKey,
  filesInProgress,
  setFilesInprogress,
}: Props) {
  function Citation({
    text,
    badge,
    files,
  }: {
    text: string | undefined
    badge: string
    files: ViewFile[] | undefined
  }) {
    const citedFiles = getPreparedFiles({
      previousFiles: files,
      filesInProgress: undefined,
      encryptKey,
    })

    return (
      <div className="citation-container">
        <Badge.Ribbon text={badge} color={'#224160'} placement="start">
          <div className="citation-container-content">
            <MdCancel
              className="cancel-btn"
              size="1.5rem"
              onClick={() => {
                dispatch(resetMessageInputDraft({ chatId: currentChatId }))
              }}
            />
            {text && <div dangerouslySetInnerHTML={{ __html: text }} />}
            <Upload
              disabled
              listType="picture"
              className="upload-list-inline"
              fileList={citedFiles}
              onPreview={handlePreview}
            />
          </div>
        </Badge.Ribbon>
      </div>
    )
  }
  const messageInputDraft = useSelector(
    (state: RootState) => state.chats.messageInputDraft[currentChatId],
  )
  const ws = useContext(ChatWebSocketConnectionContext)

  const [text, setText] = useStateWithDebouncedCallback<string>('', (newText) => {
    const _text = emptyPattern.test(newText) ? undefined : newText
    dispatch(setMessageInputDraftText({ chatId: currentChatId, text: _text }))
  })

  useChatDraft({
    onLoaded: (files) => {
      setFilesInprogress(files)
    },
    currentChatId,
    filesInProgress,
    messageInputDraft,
  })

  useChangeTypingChecker({
    onType: () => {
      ws?.sendUserTyping(currentChatId, true)
    },
    onStopTyping: () => {
      ws?.sendUserTyping(currentChatId, false)
    },
    text: text,
  })

  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    if (previousFiles) {
      const messageFiles = getPreparedFiles({
        previousFiles,
        filesInProgress,
        encryptKey,
      })
      !filesInProgress && setFilesInprogress(messageFiles)
    } else setFilesInprogress(undefined)
  }, [previousFiles])

  useEventListener('keydown', (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      messageInputDraft?.messageToEdit &&
        dispatch(setMessageInputDraftEditMsg({ chatId: currentChatId }))
      messageInputDraft?.messageToReply &&
        dispatch(setMessageInputDraftReplyMsg({ chatId: currentChatId }))
    }
  })

  const assertMessageNotEmpty = (): { text: string; files: string } | undefined => {
    const isMessageEdited = (): boolean => {
      const textToTest = emptyPattern.test(_text) ? '' : _text
      return (
        messageInputDraft?.messageToEdit?.text !== textToTest ||
        messageInputDraft?.messageToEdit?.files !== _files
      )
    }
    if (
      filesInProgress?.length &&
      filesInProgress?.every((file) => file.status !== 'done')
    )
      return undefined
    const files = filesInProgress
      ?.filter((file) => file.status === 'done')
      .map((f) => {
        if (f.response)
          return {
            id: f.response.id,
            size: f.response.size,
            mimeType: f.response.mimeType,
            name: f.response.name,
          }
      })
    const _text = text.length > 0 ? text : messageInputDraft?.messageToEdit?.text || ''
    const _files = files && files.length > 0 ? JSON.stringify(files) : ''

    const preparedMessage = {
      text: _text,
      files: _files,
    }

    if (!isMessageEdited()) return undefined

    return (_text.trim() === '' || emptyPattern.test(_text)) && _files?.length === 0
      ? undefined
      : preparedMessage
  }

  const handleSend = () => {
    const preparedMessage = assertMessageNotEmpty()
    if (!preparedMessage) return
    const _messageToReply = messageInputDraft?.messageToReply
      ? JSON.stringify(messageInputDraft.messageToReply)
      : ''
    onSend({
      text: preparedMessage.text,
      files: preparedMessage.files,
      repliedTo: _messageToReply,
    })
    if (!messageInputDraft?.messageToEdit?.text) dispatch(setScrolledToBottom(true))
    //TODO: move clear to the useAttemptsListener
    dispatch(resetMessageInputDraft({ chatId: currentChatId }))
    setFilesInprogress(undefined)
    setText('')
  }

  const handlePreview = (file: UploadFile<any>) => {
    if (
      (file.type && file.type.includes('images')) ||
      (file.response && file.response.mimeType.startsWith('image'))
    ) {
      dispatch(setImgToView({ file }))
    }
  }
  const quillRef = useRef<MessageInputQuill>(null)
  return (
    <>
      <div
        className={classNames({
          'message-input-container': true,
          'message-input-container--no-message': !assertMessageNotEmpty(),
        })}
      >
        {messageInputDraft?.messageToEdit && (
          <Citation
            badge={t('Editing')}
            files={
              messageInputDraft.messageToEdit.files.length > 0
                ? JSON.parse(messageInputDraft.messageToEdit.files)
                : undefined
            }
            text={messageInputDraft.messageToEdit?.text}
          />
        )}
        {messageInputDraft?.messageToReply && (
          <Citation
            badge={`${t('Reply to')} ${messageInputDraft.messageToReply.peerEmail}`}
            files={
              messageInputDraft.messageToReply.files.length > 0
                ? JSON.parse(messageInputDraft.messageToReply.files)
                : undefined
            }
            text={messageInputDraft.messageToReply.text}
          />
        )}
        <MessageInputQuill
          ref={quillRef}
          state={text}
          messageEditText={messageInputDraft?.messageToEdit?.text}
          messageDraftText={messageInputDraft?.text}
          setState={setText}
          onSend={handleSend}
          t={t}
          currentChatId={currentChatId}
          onUpload={onUpload}
        />
        <Upload
          listType="picture"
          fileList={filesInProgress}
          onChange={(info) => {
            setFilesInprogress(info.fileList)
          }}
          onPreview={handlePreview}
        />
        <CustomToolbar text={text} setText={setText} quillRef={quillRef} />
      </div>
      <Previewer />
    </>
  )
}
