import React, { MutableRefObject, useRef, useState } from 'react'
import classNames from 'classnames'
import { Dropdown, MenuProps, Tooltip } from 'antd'
import { RootState } from '../../store'
import { useSelector } from 'react-redux'
import RoomClient from '../../features/room/RoomClient'
import { useNavigate } from 'react-router-dom'
import { ImPhoneHangUp } from 'react-icons/im'
import { useTranslation } from 'react-i18next'
import { FiMoreHorizontal } from 'react-icons/fi'
import CallControlsWebcam from './CallControlsWebcam'
import CallControlsMic from './CallControlsMic'
import DateTime from '../Time'
import { UseTruncateText } from '../../utils/hooks/UseTruncateText'
import { useIsMobile } from '../../utils/hooks/useIsMobile'
import { callControlsLogoFilename } from '../../assets/personnalization'
import CallControlsScreenShare from './CallControlsScreenShare'
import CallControlsBlurBackground from './CallControlsBlurBackground'
import CallControlsMessages from './CallControlsMessages'
import CallControlsFullscreen from './CallControlsFullscreen'
import CallControlsParticipants from './CallControlsParticipants'
import CallControlsMenu from './CallControlsMenu'
import CallControlsSettings from './CallControlsSettings'
import CallControlsDocuments from './CallControlsDocuments'

interface Props {
  name?: string
  roomClient: MutableRefObject<RoomClient>
  documentDrawerOpen: boolean
  messagesDrawerOpen: boolean
  participantsDrawerOpen: boolean
  settingDrawerOpen: boolean
  encryptKey?: string
  setOpenDrawerDocuments: () => void
  setOpenDrawerMessages: () => void
  setOpenDrawerParticipants: () => void
  setOpenDrawerSettings: () => void
}

function CallControls({
  name,
  roomClient,
  documentDrawerOpen,
  messagesDrawerOpen,
  participantsDrawerOpen,
  settingDrawerOpen,
  encryptKey,
  setOpenDrawerDocuments,
  setOpenDrawerMessages,
  setOpenDrawerParticipants,
  setOpenDrawerSettings,
}: Props) {
  const navigate = useNavigate()
  const { t } = useTranslation('room')
  const inviteToken = useSelector((state: RootState) => state.invite.roomToken)
  const organization = useSelector((state: RootState) => state.organisation.organisation)
  const room = useSelector((state: RootState) => state.room)
  const isMobile = useIsMobile()
  const [dropdownOpened, setDropdownOpened] = useState(false)
  const [tooltipVisible, setTooltipVisible] = useState(true)
  const email = useSelector((state: RootState) => state.auth.email)
  const nameRef = useRef<HTMLHeadingElement>(null)
  const truncated = UseTruncateText(nameRef, name)
  const hideOnMouseStop = useSelector((state: RootState) => state.room.hideOnMouseStop)

  const items: MenuProps['items'] = [
    {
      key: 'settings',
      label: (
        <CallControlsSettings
          settingDrawerOpen={settingDrawerOpen}
          toggleDrawerSettings={toggleDrawerSettings}
        />
      ),
      className: 'call-controls-dropdown',
    },
    {
      key: 'messages',
      label: (
        <CallControlsMessages
          messagesDrawerOpen={messagesDrawerOpen}
          toggleDrawerMessages={toggleDrawerMessages}
        />
      ),
      className: 'call-controls-dropdown',
    },
    ...(encryptKey
      ? [
          {
            key: 'documents',
            label: (
              <CallControlsDocuments
                documentDrawerOpen={documentDrawerOpen}
                toggleDrawerDocuments={toggleDrawerDocuments}
              />
            ),
            className: 'call-controls-dropdown',
          },
        ]
      : []),
    ...(room.role === 'creator'
      ? [
          {
            key: 'participants',
            label: (
              <CallControlsParticipants
                tooltipVisible={tooltipVisible}
                participantsDrawerOpen={participantsDrawerOpen}
                toggleDrawerParticipants={toggleDrawerParticipants}
              />
            ),
            className: 'call-controls-dropdown',
          },
        ]
      : []),
  ]

  function toggleDrawerMessages() {
    setTooltipVisible(false)
    setDropdownOpened(false)
    setOpenDrawerMessages()
    setTimeout(() => setTooltipVisible(true), 100) // to reset the tooltip pause
  }

  function toggleDrawerDocuments() {
    setTooltipVisible(false)
    setDropdownOpened(false)
    setOpenDrawerDocuments()
    setTimeout(() => setTooltipVisible(true), 100) // to reset the tooltip pause
  }

  function toggleDrawerParticipants() {
    setTooltipVisible(false)
    setDropdownOpened(false)
    setOpenDrawerParticipants()
    setTimeout(() => setTooltipVisible(true), 100) // to reset the tooltip pause
  }

  function getTooltipName() {
    if (truncated) {
      return name
    }
    return ''
  }

  function toggleDrawerSettings() {
    setTooltipVisible(false)
    setDropdownOpened(false)
    setOpenDrawerSettings()
    setTimeout(() => setTooltipVisible(true), 100) // to reset the tooltip pause
  }

  return (
    <div
      className="call-controls-container"
      hidden={
        hideOnMouseStop &&
        !documentDrawerOpen &&
        !participantsDrawerOpen &&
        !messagesDrawerOpen &&
        !settingDrawerOpen
      }
    >
      <div className="call-controls">
        <div className="call-controls--left">
          <img
            className="logo"
            src={`/logo/${callControlsLogoFilename}`}
            alt="Auxasuite logo"
          />
          <div className="info-container">
            <h3 className="time">
              <DateTime />
            </h3>
            <Tooltip title={getTooltipName()}>
              <h3 className="room-name" ref={nameRef}>
                {t('Meeting', { ns: 'meetings' })} : {name}
              </h3>
            </Tooltip>
          </div>
        </div>
        {!isMobile && (
          <div className="call-controls--middle">
            <CallControlsMic roomClient={roomClient} />
            <CallControlsWebcam roomClient={roomClient} />
            <CallControlsBlurBackground roomClient={roomClient} />
            <CallControlsScreenShare roomClient={roomClient} />
            <Tooltip title={t('Hang up')}>
              <div
                className="call-control call-control--hangup"
                onClick={() => {
                  if (!organization) navigate(`/invite/meeting/${inviteToken}`)
                  else navigate('/upcoming-meetings')
                }}
              >
                <ImPhoneHangUp size="1.8rem" color="#ffffff" />
              </div>
            </Tooltip>
          </div>
        )}

        <div className="call-controls--right">
          {isMobile ? (
            <>
              <CallControlsMic roomClient={roomClient} />
              <CallControlsWebcam roomClient={roomClient} />
              <Dropdown
                menu={{ items }}
                placement="top"
                open={dropdownOpened}
                className="call-controls-dropdown"
              >
                <div
                  className={classNames({
                    'call-control': true,
                  })}
                  onClick={() => setDropdownOpened(!dropdownOpened)}
                >
                  <FiMoreHorizontal size="1.8rem" color="#ffffff" />
                </div>
              </Dropdown>
              <Tooltip title={t('Hang up')}>
                <div
                  className="call-control call-control--hangup"
                  onClick={() => {
                    if (!organization) navigate(`/invite/meeting/${inviteToken}`)
                    else navigate('/upcoming-meetings')
                  }}
                >
                  <ImPhoneHangUp size="1.8rem" color="#ffffff" />
                </div>
              </Tooltip>
            </>
          ) : (
            <>
              {email && <CallControlsMenu />}
              {encryptKey && (
                <CallControlsDocuments
                  documentDrawerOpen={documentDrawerOpen}
                  toggleDrawerDocuments={toggleDrawerDocuments}
                />
              )}
              <CallControlsMessages
                messagesDrawerOpen={messagesDrawerOpen}
                toggleDrawerMessages={toggleDrawerMessages}
              />
              {room.role === 'creator' && (
                <CallControlsParticipants
                  tooltipVisible={tooltipVisible}
                  participantsDrawerOpen={participantsDrawerOpen}
                  toggleDrawerParticipants={toggleDrawerParticipants}
                />
              )}
              <CallControlsSettings
                settingDrawerOpen={settingDrawerOpen}
                toggleDrawerSettings={toggleDrawerSettings}
              />
              <CallControlsFullscreen />
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default CallControls
