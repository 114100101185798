import React, { MutableRefObject } from 'react'
import { Button, Col, Row, Spin } from 'antd'
import { Trans, useTranslation } from 'react-i18next'
import './Antechamber.scss'
import RoomClient from '../RoomClient'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../store'
import dayjs from 'dayjs'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import RoomDevicesConfigurations from '../RoomDevicesConfiguration/RoomDevicesConfiguration'
import { BiError } from 'react-icons/bi'
import { localFormatDate } from '../../../utils/Utils'
import Layout from '../../../components/Layout/Layout'
import { unescape } from 'html-escaper'
import { useToastContext } from '../../../components/Toast/ToastContext'
import useRoomListener from '../../../utils/hooks/useRoomListener'
import { useNavigate } from 'react-router-dom'

interface Props {
  roomClient: MutableRefObject<RoomClient>
  onJoin: () => void
}

function Antechamber({ roomClient, onJoin }: Props) {
  dayjs.extend(LocalizedFormat)
  const { t } = useTranslation('antechamber')
  const room = useSelector((state: RootState) => state.room)
  const { ToastOpen } = useToastContext()
  const navigate = useNavigate()
  useRoomListener(roomClient, {
    ALLOWED_TO_JOIN: () =>
      ToastOpen({
        type: 'success',
        message: t('Moderator has approved your participation.'),
      }),
    DENIED_TO_JOIN: () =>
      ToastOpen({
        type: 'error',
        message: t('Organizer has denied your participation.'),
      }),
  })
  const email = useSelector((state: RootState) => state.invite.email)
  const isInvited = email != undefined

  return (
    <Layout
      content={
        <div className="Antechamber">
          <Row className="Antechamber-header-container" align={'middle'}>
            <Col xxl={7} xl={6} lg={4} md={4} sm={2} />
            <Col xxl={10} xl={12} lg={16} md={16} sm={20}>
              {room.fetchAccessTokenStatus === 'success' && (
                <div className="Antechamber-main-title-container">
                  {isInvited && room.name && room.startDate ? (
                    <div className="invited">
                      <h1 className="Antechamber-main-title Antechamber-main-title--invited">
                        <Trans
                          ns="antechamber"
                          i18nKey="INVITED_WAITING_TITLE_HEADER"
                          values={{
                            platform: process.env.REACT_APP_PROD_NAME || 'Auxamed',
                            name: unescape(room.name),
                          }}
                          components={{ bold: <strong /> }}
                        />{' '}
                        <strong>{localFormatDate(dayjs(room.startDate))}</strong>.
                      </h1>

                      {room.allowed === 'unknown' && (
                        <div className="Antechamber-room-title">
                          {t(
                            'You are currently awaiting approval by the organizer, please be patient.',
                          )}
                        </div>
                      )}
                      {room.allowed === 'allowed' && (
                        <div className="Antechamber-room-title">
                          {t('You can now join the meeting.')}
                        </div>
                      )}
                    </div>
                  ) : (
                    <>
                      <h1 className="Antechamber-main-title">
                        {t('Meeting of')}{' '}
                        {room.startDate && localFormatDate(dayjs(room.startDate))}
                      </h1>
                      <div className="Antechamber-room-title">
                        <strong>
                          {t('Title', { ns: 'meetings' })}
                          {' : '}
                        </strong>
                        <span className="room-name">{unescape(room.name || '')}</span>
                      </div>
                    </>
                  )}
                </div>
              )}
            </Col>
            <Col xxl={7} xl={6} lg={4} md={4} sm={2} />
          </Row>
          <div className="Antechamber-main">
            {room.fetchAccessTokenStatus === 'loading' && (
              <div className="d-flex d-flex-center mt-1rem h-30rem">
                <Spin /> {t('Connecting, wait please')}
              </div>
            )}

            {room.fetchAccessTokenStatus === 'error' && (
              <div className="Antechamber-connection-error">
                <BiError size="4em" color="#ff4d4f" className="mt-2rem" />
                <div>{t('You are not authorized to access this meeting.')}</div>
              </div>
            )}

            {room.fetchAccessTokenStatus === 'success' && (
              <Row>
                <Col xxl={7} xl={6} lg={4} sm={2} />
                <Col xxl={10} xl={12} lg={16} sm={20}>
                  <RoomDevicesConfigurations roomClient={roomClient} />
                </Col>
                <Col xxl={7} xl={6} lg={4} sm={2} />
              </Row>
            )}
          </div>
          {room.fetchAccessTokenStatus === 'success' && (
            <div className="Antechamber-join-button-container">
              {!isInvited && (
                <Button onClick={() => navigate('/applications')} className="back-btn">
                  {t('Back', { ns: 'common' })}
                </Button>
              )}
              <Button
                type="primary"
                disabled={room.allowed !== 'allowed'}
                onClick={onJoin}
              >
                {(!room.allowed && t('Connecting, wait please')) ||
                  (room.allowed === 'unknown' &&
                    t("Please wait for organizer's approval")) ||
                  (room.allowed === 'prohibited' &&
                    t('Organizer has denied your participation.')) ||
                  t('Join this meeting')}
              </Button>
            </div>
          )}
        </div>
      }
      contentFullSize={true}
      primaryContent={true}
      footer={true}
    />
  )
}

export default Antechamber
