import React, { MutableRefObject, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import { Button } from 'antd'
import RoomClient from '../../features/room/RoomClient'
import { Trans, useTranslation } from 'react-i18next'
import { useToastContext } from '../Toast/ToastContext'

interface Props {
  roomClient: MutableRefObject<RoomClient>
}

function CallNotification({ roomClient }: Props) {
  const { t } = useTranslation('meetings')
  const room = useSelector((state: RootState) => state.room)
  const [waitingNumber, setWaitingNumber] = useState<number>(0)
  const [networkTroubleNumber, setNetworkTroubleNumber] = useState<number>(0)
  const email = useSelector((state: RootState) => state.auth.email)
  const { ToastOpen, ToastDestroy } = useToastContext()

  function refusedPeer(email: string) {
    roomClient.current.prohibitInvited(email)
    ToastDestroy(email)
  }

  function acceptPeer(email: string) {
    roomClient.current.allowInvited(email)
    ToastDestroy(email)
  }

  useEffect(() => {
    if (waitingNumber < room.waitingInvited.length && room.role === 'creator') {
      if (email && email != room.waitingInvited[room.waitingInvited.length - 1].email) {
        ToastOpen({
          key: room.waitingInvited[room.waitingInvited.length - 1].email,
          message: (
            <Trans
              ns="meetings"
              i18nKey="PARTICIPANT_WAITING"
              values={{
                email: room.waitingInvited[room.waitingInvited.length - 1].email,
              }}
              components={{ bold: <strong /> }}
            />
          ),
          description: (
            <div className="d-flex d-flex-right g-0_5rem">
              <Button
                className="toast-button"
                type="default"
                onClick={() =>
                  refusedPeer(room.waitingInvited[room.waitingInvited.length - 1].email)
                }
              >
                {t('Refuse')}
              </Button>
              <Button
                className="toast-button"
                type="primary"
                onClick={() =>
                  acceptPeer(room.waitingInvited[room.waitingInvited.length - 1].email)
                }
              >
                {t('Accept')}
              </Button>
            </div>
          ),
          duration: 0,
          type: 'info',
        })
      }
    }
    setWaitingNumber(room.waitingInvited.length)
  }, [room.waitingInvited.length])

  useEffect(() => {
    //ToastDestroy()
    if (
      networkTroubleNumber < room.networkTroubleUsers.length &&
      room.role === 'creator'
    ) {
      if (
        email &&
        email != room.networkTroubleUsers[room.networkTroubleUsers.length - 1]
      ) {
        ToastOpen({
          message: (
            <Trans
              ns="meetings"
              i18nKey="PARTICIPANT_NETWORK_TROUBLE"
              values={{
                email: room.networkTroubleUsers[room.networkTroubleUsers.length - 1],
              }}
            />
          ),
          type: 'info',
          duration: 10,
        })
      }
    }
    setNetworkTroubleNumber(room.networkTroubleUsers.length)
  }, [room.networkTroubleUsers.length])

  useEffect(() => {
    return () => {
      ToastDestroy()
    }
  }, [])

  return <></>
}

export default CallNotification
