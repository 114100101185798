import React, { useEffect, useImperativeHandle, useRef, useState } from 'react'
import classnames from 'classnames'
import hark from 'hark'
import './PeerView.scss'
import { BsMicMute } from 'react-icons/bs'
import { HiOutlineZoomOut, HiOutlineZoomIn } from 'react-icons/hi'
import { Me } from './redux/meSlice'
import { PeerModel } from './redux/model'
import VolumeLevel, { VolumeLevelInterface } from './VolumeLevel/VolumeLevel'
import Draggable from 'react-draggable'
import { AppDispatch, RootState } from '../../store'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { setPromotedFullScreen, setPromotedPeer } from './redux/roomSlice'
import classNames from 'classnames'
import { Tooltip } from 'antd'
import { TbZoomIn, TbZoomOut, TbZoomReset } from 'react-icons/tb'
import { FiInfo } from 'react-icons/fi'

interface Props {
  isMe: boolean
  isMyWebcam: boolean
  peer?: PeerModel
  me?: Me
  displayName: string
  consumerCurrentSpatialLayer?: number | null
  audioTrack: any
  audioTrackPaused?: boolean
  videoTrack: any
  videoVisible: boolean
  videoMultiLayer?: boolean
  videoScore: any
  shareTrack: any
  showCanvas?: boolean
  onResolutionChange?: (width: number, height: number) => void
}

export type PeerViewRefType = {
  video: React.RefObject<HTMLVideoElement>
  audio: React.RefObject<HTMLAudioElement>
  canvas: React.RefObject<HTMLCanvasElement>
}

const PeerView = React.forwardRef<PeerViewRefType, Props>((props, ref) => {
  const {
    isMe,
    isMyWebcam,
    peer,
    me,
    displayName,
    consumerCurrentSpatialLayer,
    audioTrack,
    audioTrackPaused,
    videoTrack,
    videoVisible,
    videoMultiLayer,
    videoScore,
    shareTrack,
    showCanvas,
    onResolutionChange,
  } = props
  const videoRef = useRef<HTMLVideoElement>(null)
  const shareRef = useRef<HTMLVideoElement>(null)
  const audioRef = useRef<HTMLAudioElement & { setSinkId(deviceId: string): void }>(null)
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const VolumeLevelComponentRef = useRef<VolumeLevelInterface>(null)
  const room = useSelector((state: RootState) => state.room)
  const Peers = useSelector((state: RootState) => state.room.peers)
  const numPeers = Object.keys(Peers).length
  const { t } = useTranslation('room')
  const dispatch = useDispatch<AppDispatch>()
  const promotedFullScreen = useSelector(
    (state: RootState) => state.room.promotedFullScreen,
  )
  const promotedPeer = useSelector((state: RootState) => state.room.promotedPeer)
  const hideOnMouseStop = useSelector((state: RootState) => state.room.hideOnMouseStop)

  // Latest received audio track.
  let _audioTrack: MediaStreamTrack | null = null

  // Latest received video track.
  let _videoTrack: MediaStreamTrack | null = null

  // Latest received share video track.
  let _shareTrack: MediaStreamTrack | null = null

  // Hark instance.
  let _hark: hark.Harker | null = null

  const [audioVolume, setAudioVolume] = useState<number>()
  const [videoCanPlay, setVideoCanPlay] = useState<boolean>(false)

  useImperativeHandle(ref, () => {
    return {
      get video() {
        return videoRef
      },
      get audio() {
        return audioRef
      },
      get canvas() {
        return canvasRef
      },
    }
  }, [])

  useEffect(() => {
    if (audioRef.current && !isMe && room.speakerId && audioRef.current.setSinkId) {
      audioRef.current.setSinkId(room.speakerId)
    }
  }, [room.speakerId])

  useEffect(() => {
    setTracks(audioTrack, videoTrack, shareTrack)
  }, [audioTrack, videoTrack, shareTrack])

  function onResolutionChangeInner() {
    if (onResolutionChange) {
      onResolutionChange(
        videoRef.current?.videoWidth || 0,
        videoRef.current?.videoHeight || 0,
      )
    }
  }

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener('resize', onResolutionChangeInner)

      return () => {
        videoRef.current?.removeEventListener('resize', onResolutionChangeInner)
      }
    }
  }, [videoRef.current])

  /**
   *
   */
  function renderVideo(reduced: boolean) {
    return (
      <video
        ref={videoRef}
        className={classnames({
          'is-my-webcam': isMyWebcam,
          'cursor-grab': reduced,
          draggable: reduced,
          'is-webcam-reduced': reduced,
          'network-error':
            videoVisible && videoMultiLayer && consumerCurrentSpatialLayer === null,
          'on-full-screen-reduced-cam': promotedFullScreen,
        })}
        autoPlay
        playsInline
        muted
        controls={false}
        hidden={showCanvas || !videoVisible || !videoCanPlay}
      />
    )
  }

  /**
   * Set tracks.
   * @param audioTrack
   * @param videoTrack
   * @returns
   */
  const setTracks = (
    audioTrack: MediaStreamTrack,
    videoTrack: MediaStreamTrack,
    shareTrack: MediaStreamTrack,
  ) => {
    if (
      _audioTrack === audioTrack &&
      _videoTrack === videoTrack &&
      _shareTrack === shareTrack
    ) {
      return
    }

    _audioTrack = audioTrack
    _videoTrack = videoTrack
    _shareTrack = shareTrack

    if (_hark) {
      _hark.stop()
    }

    const audioElem = audioRef.current
    if (audioElem) {
      if (audioTrack) {
        const stream = new MediaStream()
        stream.addTrack(audioTrack)

        audioElem.srcObject = stream
        audioElem
          .play()
          .catch((error: any) => console.warn('audioElem.play() failed:%o', error))

        runHark(stream)
      } else {
        audioElem.srcObject = null
      }
    } else {
      console.error('"audioElem" is not ready but "setTracks" is called')
    }

    const videoElem = videoRef.current
    if (videoElem) {
      if (videoTrack) {
        if (isMe == false) {
          videoTrack.onunmute = () => {
            console.log(`isMe=${isMe} ${peer?.id} videoTrack.onunmute`)
          }

          videoTrack.onended = () => {
            console.log(`isMe=${isMe} ${peer?.id} videoTrack.onended`)
          }

          videoTrack.onmute = () => {
            console.log(`isMe=${isMe} ${peer?.id} videoTrack.onmute`)
          }

          console.log(
            `isMe=${isMe} ${peer?.id} videoTrack.readyState ${videoTrack.readyState}`,
          )
        }

        const stream = new MediaStream()
        stream.addTrack(videoTrack)

        videoElem.srcObject = stream

        videoElem.oncanplay = (event: any) => {
          setVideoCanPlay(true)
          console.log(`isMe=${isMe} ${peer?.id} videoElem.oncanplay`, event)
        }

        videoElem.onplay = (event: any) => {
          if (audioElem) {
            audioElem.play().catch((error: any) => {
              console.error(`isMe=${isMe} ${peer?.id} audioElem.play`, error)
            })
          } else {
            console.error(
              `isMe=${isMe} ${peer?.id} audioElem.onplay: is not ready but "setTracks -> videoElem.onplay" is called`,
            )
          }
        }

        videoElem.onpause = () => {
          console.log(`isMe=${isMe} ${peer?.id} videoElem.onpause`)
        }

        videoElem.onerror = (e: any) => {
          console.error(`isMe=${isMe} ${peer?.id} videoElem.onerror`, e)
        }

        videoElem.onended = () => {
          console.error(`isMe=${isMe} ${peer?.id} videoElem.onended`)
        }

        videoElem.onprogress = () => {
          console.log(`isMe=${isMe} ${peer?.id} videoElem.progress`)
        }

        videoElem.play().catch((error: any) => {
          console.error(`isMe=${isMe} ${peer?.id} videoElem.play`, error)
        })
      } else {
        // videoElem.srcObject = null
        console.warn(`isMe=${isMe} ${peer?.id} videoTrack=NULL`)
      }
    } else {
      console.error(
        `isMe=${isMe} ${peer?.id} "videoElem" is not ready but "setTracks" is called`,
      )
    }

    const shareElem = shareRef.current
    if (shareElem) {
      if (shareTrack) {
        if (isMe == false) {
          shareTrack.onunmute = () => {
            console.log(`isMe=${isMe} ${peer?.id} shareTrack.onunmute`)
          }

          shareTrack.onended = () => {
            console.log(`isMe=${isMe} ${peer?.id} shareTrack.onended`)
          }

          shareTrack.onmute = () => {
            console.log(`isMe=${isMe} ${peer?.id} shareTrack.onmute`)
          }

          console.log(
            `isMe=${isMe} ${peer?.id} shareTrack.readyState ${shareTrack.readyState}`,
          )
        }

        const stream = new MediaStream()
        stream.addTrack(shareTrack)

        shareElem.srcObject = stream

        shareElem.oncanplay = (event) => {
          console.log(`isMe=${isMe} ${peer?.id} shareElem.oncanplay`, event)
        }

        shareElem.onpause = () => {
          console.log(`isMe=${isMe} ${peer?.id} shareElem.onpause`)
        }

        shareElem.play().catch((error) => {
          console.error(`isMe=${isMe} ${peer?.id} shareElem.play`, error)
        })
      } else {
        // shareElem.srcObject = null
        console.warn(`isMe=${isMe} ${peer?.id} videoTrack=NULL`)
      }
    } else {
      console.error(
        `isMe=${isMe} ${peer?.id} "shareElem" is not ready but "setTracks" is called`,
      )
    }
  }

  /**
   * Run hark on audio stream.
   * @param stream
   */
  const runHark = (stream: MediaStream) => {
    if (!stream.getAudioTracks()[0]) {
      throw new Error('runHark() | given stream has no audio track')
    }

    _hark = hark(stream, { play: false })

    // eslint-disable-next-line no-unused-vars
    _hark.on('volume_change', (dBs, threshold) => {
      VolumeLevelComponentRef.current?.setVolume(dBs)
    })
  }

  const splittedDisplayName = displayName.split(' ')

  const handleResetZoom = () => {
    dispatch(setPromotedFullScreen(false))
    dispatch(setPromotedPeer(undefined))
  }

  return (
    <div className="PeerView">
      <div className="display-name">
        {(!audioTrack || audioTrackPaused) && (
          <div className="PeerView-media">
            <BsMicMute size="1.1em" />
          </div>
        )}
        <Tooltip title={peer ? peer.id : ''}>
          <span className="display-name-text">{displayName}</span>
        </Tooltip>
      </div>

      <div className="PeerView-initials">
        {(splittedDisplayName[0] || '?').slice(0, 1)}
        {(splittedDisplayName[1] && splittedDisplayName[1].slice(0, 1)) ||
          (splittedDisplayName[0] || '?').slice(1, 2)}
      </div>

      {!hideOnMouseStop && numPeers > 0 && (
        <div className="zoom-buttons">
          {peer && (
            <>
              {promotedPeer === peer.id ? (
                promotedFullScreen ? (
                  <>
                    <Tooltip title={t('Zoom out')}>
                      <div className="zoom-button">
                        <HiOutlineZoomOut
                          className="zoom-icon"
                          onClick={() =>
                            dispatch(setPromotedFullScreen(!promotedFullScreen))
                          }
                        />
                      </div>
                    </Tooltip>
                    <Tooltip title={t('Reset', { ns: 'common' })}>
                      <div className="zoom-button">
                        <TbZoomReset className="zoom-icon" onClick={handleResetZoom} />
                      </div>
                    </Tooltip>
                  </>
                ) : (
                  <>
                    <Tooltip title={t('Zoom out')}>
                      <div className="zoom-button">
                        <TbZoomOut
                          className="zoom-icon"
                          onClick={() =>
                            promotedPeer === peer.id
                              ? dispatch(setPromotedPeer(undefined))
                              : dispatch(setPromotedPeer(peer.id))
                          }
                        />
                      </div>
                    </Tooltip>
                    <Tooltip title={t('Zoom in')}>
                      <div className="zoom-button">
                        <TbZoomIn
                          className="zoom-icon"
                          onClick={() =>
                            dispatch(setPromotedFullScreen(!promotedFullScreen))
                          }
                        />
                      </div>
                    </Tooltip>
                  </>
                )
              ) : (
                <Tooltip title={t('Zoom in')}>
                  <div className="zoom-button">
                    <TbZoomIn
                      className="zoom-icon"
                      onClick={() =>
                        promotedPeer === peer.id
                          ? dispatch(setPromotedPeer(undefined))
                          : dispatch(setPromotedPeer(peer.id))
                      }
                    />
                  </div>
                </Tooltip>
              )}
            </>
          )}

          {me && numPeers > 0 && (
            <>
              {promotedPeer === 'me' ? (
                <Tooltip title={t('Zoom out')}>
                  <div className="zoom-button">
                    <TbZoomOut
                      className="zoom-icon"
                      onClick={() =>
                        promotedPeer === 'me'
                          ? dispatch(setPromotedPeer(undefined))
                          : dispatch(setPromotedPeer('me'))
                      }
                    />
                  </div>
                </Tooltip>
              ) : (
                <Tooltip title={t('Zoom in')}>
                  <div className="zoom-button">
                    <TbZoomIn
                      className="zoom-icon"
                      onClick={() =>
                        promotedPeer === 'me'
                          ? dispatch(setPromotedPeer(undefined))
                          : dispatch(setPromotedPeer('me'))
                      }
                    />
                  </div>
                </Tooltip>
              )}
            </>
          )}
        </div>
      )}

      <video
        className={classnames({ 'on-full-screen': promotedFullScreen })}
        ref={shareRef}
        autoPlay
        playsInline
        muted
        controls={false}
        hidden={!shareTrack}
      />

      {shareTrack ? (
        <Draggable bounds="parent">{renderVideo(true)}</Draggable>
      ) : (
        <>{renderVideo(false)}</>
      )}

      <canvas
        className={classnames({
          PeerView_canvas: true,
          'is-webcam-reduced': shareTrack,
          'is-my-webcam': isMyWebcam,
        })}
        ref={canvasRef}
        hidden={!showCanvas || !videoVisible || !videoCanPlay}
      />

      <audio ref={audioRef} autoPlay muted={isMe} controls={false} />

      {!shareTrack &&
        (showCanvas || !videoVisible || !videoCanPlay) &&
        (!showCanvas || !videoVisible || !videoCanPlay) && (
          <div
            className={classNames('PeerView-filler', {
              'PeerView-filler-full-screen': promotedFullScreen,
            })}
          />
        )}

      <div className="after-video-container">
        <VolumeLevel ref={VolumeLevelComponentRef} />
      </div>
    </div>
  )
})

PeerView.displayName = 'PeerView'
export default PeerView
