import React, { Dispatch, SetStateAction, useState } from 'react'
import { AutoComplete, Button, Col, Form, FormInstance, Tooltip } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../store'
import { useTranslation } from 'react-i18next'
import { RoomInterface } from '../../../features/room/RoomInterface'
import './AddParticipantForm.scss'
import { searchUsersAndInvites } from '../../../features/Organisation/OrganisationService'
import { SelectOption } from '../../DrivesComponent/CreateDrive/DriveFormAddParticipant'
import { IoTrashOutline } from 'react-icons/io5'
import { removeInvited } from '../../../features/invite/redux/inviteSlice'
import { KnownParticipants } from './AddParticipantForm'

export interface Option {
  label: React.ReactNode
  options: SelectOption[]
}

interface ParticipantSearchProps {
  room?: RoomInterface
  form: FormInstance
  options: Option[]
  setOptions: Dispatch<SetStateAction<Option[]>>
  knownPeers: KnownParticipants
  dispatchKnownPeers: Dispatch<SetStateAction<KnownParticipants>>
}

function ParticipantSearch({
  room,
  form,
  options,
  setOptions,
  knownPeers,
  dispatchKnownPeers,
}: ParticipantSearchProps) {
  const organizationId = useSelector(
    (state: RootState) => state.organisation.organisation?.id,
  )
  const { t } = useTranslation('meetings')
  const jwt = useSelector((state: RootState) => state.auth.jwt) || ''
  const email = useSelector((state: RootState) => state.auth.email) || ''
  const dispatch = useDispatch<AppDispatch>()
  const renderTitle = (title: string) => <span>{title}</span>

  function deleteInvited(email: string) {
    dispatch(removeInvited({ jwt, service: 'meeting', email }))
    setTimeout(() => {
      form.resetFields(['newParticipant'])
    }, 10)
  }

  const onSelect = (data: string, option: any) => {
    setOptions([])
  }

  const onSearch = async (searchText: string) => {
    if (organizationId) {
      setOptions([])

      try {
        const usersAndInvites = await searchUsersAndInvites(
          jwt,
          searchText,
          organizationId,
          'meeting',
          [
            ...(room?.participants?.map((p) => p.email) || []),
            ...(room?.invited?.map((i) => i.email) || []),
            email,
          ],
        )

        const usersSelectOptions: SelectOption[] = usersAndInvites.users.map(
          ({ email, firstName, lastName }) => ({
            id: email,
            label: `${firstName} ${lastName} (${email})`,
            value: email,
            type: 'user',
            firstName,
            lastName,
          }),
        )

        const inviteSelectOptions: SelectOption[] = usersAndInvites.invites.map((i) => {
          return {
            id: i.email,
            label: (
              <span className="d-flex d-flex-between">
                <Tooltip title={i.email}>
                  <div className="room-participant-item-body-email-max">{i.email}</div>
                </Tooltip>
                <Button
                  className="btn-danger-border"
                  onClick={() => deleteInvited(i.email)}
                >
                  <IoTrashOutline className="error-color" />
                </Button>
              </span>
            ),
            value: i.email,
            type: 'invite',
          }
        })

        dispatchKnownPeers({
          ...knownPeers,
          ...usersSelectOptions.reduce((collector, user) => {
            if (user.firstName && user.lastName) {
              collector[user.value] = {
                firstName: user.firstName,
                lastName: user.lastName,
              }
            }
            return collector
          }, {} as KnownParticipants),
        })

        const optionsFull: Option[] = [
          {
            label: renderTitle(t('Users', { ns: 'layout' })),
            options: usersSelectOptions,
          },
          {
            label: renderTitle(t('Guests', { ns: 'layout' })),
            options: inviteSelectOptions,
          },
        ]
        setOptions(optionsFull)
      } catch (e) {
        console.error(e)
      }
    }
  }

  return (
    <Form.Item
      name="newParticipant"
      rules={[
        {
          required: true,
          message: t('Please input participant name'),
        },
        {
          type: 'email',
          message: t('The format of the email does not comply', { ns: 'common' }),
          validateTrigger: 'onSubmit',
          transform: (value) => value.trim(),
        },
      ]}
    >
      <AutoComplete
        allowClear
        options={options}
        onSelect={onSelect}
        onSearch={onSearch}
        placeholder={t('Search participant')}
      />
    </Form.Item>
  )
}

export default ParticipantSearch
