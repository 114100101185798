import React, { ReactNode, useContext, useState } from 'react'
import { Tooltip, Modal, List, Avatar, Button } from 'antd'
import classNames from 'classnames'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../store'
import ChatMessageFile from './ChatMessageFile'
import './ChatMessages.scss'
import { Chat, ChatMessage, setCurrentChatId } from './chatsSlice'
import MessageText from './MessageText'
import { BsFillReplyFill } from 'react-icons/bs'
import { generateUniqueColor } from '../../../features/room/RoomChat/ChatLibrary'
import { localFormatDate } from '../../../utils/Utils'
import ChatMessageActions from './ChatMessageActions'
import useScrollToMessage from '../../../utils/hooks/useScrollToMessage'
import { ImBlocked } from 'react-icons/im'
import ChatReactions from './ChatReactions'
import { IoCheckmarkDone, IoCheckmark } from 'react-icons/io5'
import { SearchUserDTO } from '../../../features/Organisation/OrganisationService'

interface MessageProps {
  //TODO: this interface can be exported and used in ChatMessageActions. try and see eslint problem
  chat: Chat
  message: ChatMessage
  citation?: true
}

export function DeletedMessageContent() {
  const { t } = useTranslation('chat')

  return (
    <div className="deleted_message_content">
      <ImBlocked size="0.75rem" />
      <em>{t('This message was deleted')}</em>
    </div>
  )
}

export default function Message({
  message,
  chat,
  citation,
  openEmojiPickerModal,
}: MessageProps & {
  openEmojiPickerModal: (e: React.MouseEvent<HTMLElement>, messageId: string) => void
}) {
  const { t } = useTranslation('chat')
  const repliedTo: ChatMessage | null =
    message.repliedTo?.length > 0 ? JSON.parse(message.repliedTo) : null
  const invite = useSelector((state: RootState) => state.invite)
  const username = useSelector((state: RootState) => state.auth.email || invite.email)
  return (
    <div
      className={
        !citation && message.peerEmail === username
          ? 'chat_message-item--self'
          : 'chat_message-item--other'
      }
    >
      <div className="chat_message-item-sizer">
        <MessageContainer message={message} chat={chat} citation={citation}>
          {message.deleted ? (
            <DeletedMessageContent />
          ) : (
            <>
              {repliedTo && !citation && (
                <Message
                  message={repliedTo}
                  chat={chat}
                  citation
                  openEmojiPickerModal={openEmojiPickerModal}
                />
              )}
              {message.text && <MessageText text={message.text} />}
              {message.files && (
                <ChatMessageFile
                  encryptKey={chat.fileEncryptionKey}
                  content={message.files}
                  withControls={true}
                />
              )}
            </>
          )}
          <ChatMessageActions
            message={message}
            citation={citation}
            chat={chat}
            openEmojiPickerModal={openEmojiPickerModal}
          />
          {!message.deleted && (
            <ChatReactions
              message={message}
              citation={citation}
              chat={chat}
              openEmojiPickerModal={openEmojiPickerModal}
            />
          )}
        </MessageContainer>
      </div>
    </div>
  )
}

function MessageContainer({
  message,
  chat,
  citation,
  children,
}: MessageProps & { children: ReactNode }) {
  const { t } = useTranslation('chat')
  const invite = useSelector((state: RootState) => state.invite)
  const username = useSelector((state: RootState) => state.auth.email || invite.email)
  const messageToEdit = useSelector(
    (state: RootState) => state.chats.messageInputDraft[chat.id]?.messageToEdit,
  )
  const dispatch = useDispatch<AppDispatch>()
  const chats = useSelector((state: RootState) => state.chats.chats[chat.squadId])
  const [isSeenByModalVisible, setIsSeenByModalVisible] = useState(false)
  const scrollToMessage = useScrollToMessage(chat.id, chat.squadId)

  const p2pChatsByPeer = chats.reduce((result: { [peer: string]: string }, chat) => {
    if (chat.peerToPeer && chat.peer) {
      result[chat.peer.email] = chat.id
    }

    return result
  }, {})

  const chatActivity = useSelector(
    (state: RootState) => state.chats.chatsDetails[chat.id]?.chatActivity,
  )

  let selfMessage = message.peerEmail === username
  let seenBy: { email: string; firstName: string; lastName: string } | undefined
  let seenByDetails: {
    email: string
    firstName: string
    lastName: string
    seenAt: string
  }[] = []
  let seenByCount = 0

  if (selfMessage) {
    for (const [peerEmail, activity] of Object.entries(chatActivity)) {
      if (
        peerEmail != message.peerEmail &&
        dayjs(activity.seenMessagesAt).isAfter(message.createdAt)
      ) {
        seenByDetails.push({
          email: peerEmail,
          firstName: activity.peerFirstName,
          lastName: activity.peerLastName,
          seenAt: activity.seenMessagesAt,
        })
        seenByCount++
      }
    }
  }

  function peerDisplayName(message: ChatMessage) {
    return message.peerFirstName && message.peerLastName
      ? `${message.peerFirstName} ${message.peerLastName}`
      : message.peerEmail
  }

  function peerInitials(message: ChatMessage): string {
    return message.peerFirstName && message.peerLastName
      ? message.peerFirstName.substring(0, 1) +
          message.peerLastName.substring(0, 1).toUpperCase()
      : message.peerEmail.substring(0, 2).toUpperCase()
  }

  function goToPeer2PeerChat(chatId: string) {
    dispatch(setCurrentChatId(chatId))
  }

  function formatSeenDate(seenAt: string) {
    const seenDate = dayjs(seenAt)
    if (seenDate.isSame(dayjs(), 'day')) {
      return t('Today')
    } else if (seenDate.isSame(dayjs().subtract(1, 'day'), 'day')) {
      return t('Yesterday')
    } else {
      return seenDate.format('LL')
    }
  }

  return (
    <div
      className={classNames({
        'chat_message-container': true,
        'chat_message-container--self': !citation && message.peerEmail === username,
      })}
    >
      <div
        className={classNames({
          chat_message: true,
          'chat_message--citation': citation,
          'chat_message--self': !citation && message.peerEmail === username,
          'chat_message--self-edit': !citation && message.id === messageToEdit?.id,
          'chat_message--other': !citation && message.peerEmail !== username,
        })}
        id={
          citation
            ? undefined /* to avoid highlighting of cited msg in scrollToMessage */
            : message.id
        }
        key={message.id}
        style={{
          borderLeft: `${
            citation ? `3px solid ${generateUniqueColor(message.peerEmail)}` : ''
          }`,
        }}
        onClick={() => {
          citation && scrollToMessage(message.id)
        }}
      >
        {(p2pChatsByPeer[message.peerEmail] || message.peerEmail !== username) &&
          !citation && (
            <div
              className="chat_message_author_icon"
              style={{
                backgroundColor: generateUniqueColor(message.peerEmail),
              }}
            >
              {peerInitials(message)}
            </div>
          )}
        <div className="chat_message_header">
          <div
            className={`chat_message_author ${
              p2pChatsByPeer[message.peerEmail] || message.peerEmail !== username
                ? 'chat_message_author--clickable'
                : ''
            }`}
            onClick={() => {
              if (p2pChatsByPeer[message.peerEmail]) {
                goToPeer2PeerChat(p2pChatsByPeer[message.peerEmail])
              }
            }}
          >
            {p2pChatsByPeer[message.peerEmail] || message.peerEmail !== username ? (
              <Tooltip title={message.peerEmail}>{peerDisplayName(message)}</Tooltip>
            ) : (
              <>{t('You', { ns: 'room' })}</>
            )}
          </div>
        </div>
        <div className="chat_message-body">{children}</div>
        <div className="chat_message_footer">
          <div className="chat_message_date">
            <Tooltip
              title={
                message.editedAt && !citation ? (
                  <>
                    {t('Created on') + ` ${localFormatDate(dayjs(message.createdAt))}`}
                    <br />
                    {t('Edited on') + ` ${localFormatDate(dayjs(message.editedAt))}`}
                  </>
                ) : (
                  dayjs(message.createdAt).format('LLL')
                )
              }
              trigger={['hover', 'click']}
            >
              {dayjs(message.createdAt).format('LT')}
              {!citation && message.editedAt && (
                <>
                  &nbsp;-&nbsp;{' '}
                  {dayjs().format('LL') === dayjs(message.editedAt).format('LL')
                    ? t('Edited at') + ` ${dayjs(message.editedAt).format('LT')}`
                    : t('Edited on') + ` ${localFormatDate(dayjs(message.editedAt))}`}
                </>
              )}
              {citation && <BsFillReplyFill />}
            </Tooltip>
          </div>

          {selfMessage && (
            <Tooltip
              title={
                seenByCount > 0
                  ? seenBy
                    ? seenByCount > 1
                      ? t('SEEN_BY_X_AND_N_OTHERS', {
                          peer: `${seenByDetails[0].firstName} ${seenByDetails[0].lastName}`,
                          nOthers: seenByCount - 1,
                        })
                      : t('SEEN_BY_X', {
                          peer: `${seenByDetails[0].firstName} ${seenByDetails[0].lastName}`,
                        })
                    : t('SEEN_BY_N', {
                        seenByCount,
                      })
                  : t('Delivered')
              }
            >
              <span
                onClick={() => seenByCount > 0 && setIsSeenByModalVisible(true)}
                style={{ cursor: 'pointer' }}
              >
                {seenByCount > 0 ? <IoCheckmarkDone /> : <IoCheckmark />}
              </span>
            </Tooltip>
          )}
        </div>
      </div>
      <Modal
        centered
        title={t('SEEN_BY')}
        open={isSeenByModalVisible}
        onCancel={() => setIsSeenByModalVisible(false)}
        cancelText={t('Close', { ns: 'common' })}
        okButtonProps={{ style: { display: 'none' } }}
      >
        <List
          className="seen-modal-list"
          dataSource={seenByDetails}
          renderItem={(user) => {
            return (
              <List.Item>
                <List.Item.Meta
                  className="item"
                  avatar={
                    <Avatar style={{ backgroundColor: generateUniqueColor(user.email) }}>
                      {user.firstName.charAt(0)}
                      {user.lastName.charAt(0)}
                    </Avatar>
                  }
                  title={
                    <div className="label">
                      <div className="user">
                        <span className="name">
                          {user.firstName} {user.lastName}
                        </span>
                        <span className="mail">{user.email}</span>
                      </div>
                      <div>
                        <span className="date">
                          {formatSeenDate(user.seenAt)}, {dayjs(user.seenAt).format('LT')}
                        </span>
                      </div>
                    </div>
                  }
                />
              </List.Item>
            )
          }}
        />
      </Modal>
    </div>
  )
}
